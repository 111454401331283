import { Button, TextField, ThemeProvider } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { theme } from "./theme-provider";

export const App = () => {
  const [emailAddress, setEmailAddress] = useState("");
  const [birthdate, setBirthdate] = useState(null);

  async function handleSubmit() {
    const dateOfBirth = birthdate.format();
    const month = birthdate.month();
    const day = birthdate.day();

    const request = {
      emailAddress,
      dateOfBirth,
      month,
      day,
    };

    const response = fetch(
      "https://kidqmcft67.execute-api.us-east-2.amazonaws.com/users",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      }
    );

    console.log(response.json());
  }

  return (
    <ThemeProvider theme={theme}>
      <div id="main-container">
        <div className="signup-container">
          <div className="signup-form">
            <h1>Starry Suggestion</h1>
            <p>
              Discover your daily horoscope and personalized product
              recommendations tailored to your astrological profile.
            </p>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TextField
                id="outlined-helperText"
                className="field"
                label="Email address"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
              <DatePicker
                label="Birthdate"
                className="field"
                value={birthdate}
                onChange={(e) => setBirthdate(e)}
              />
              <Button variant="contained" onClick={handleSubmit}>
                Get Groovy
              </Button>
            </LocalizationProvider>
          </div>
        </div>
        <div className="extra-info-container">
          <h2>Unlock your cosmic potential</h2>

          <h3>What is Starry Suggestion</h3>
          <p>
            Starry Suggestion is a service that sends you a daily email with
            your horoscope and product recommendations based on your cosmology.
            Our team of astrologers and trend experts carefully curate the
            content to align with your unique astrological profile.
          </p>
          <h3>How do you determine my horoscope and recommendations?</h3>

          <p>
            When you sign up, we collect your name and birthday. Our
            astrological experts use this information to calculate your birth
            chart and determine your zodiac sign, ruling planet, and other
            celestial influences. This data is then used to personalize your
            daily horoscope and product recommendations.
          </p>

          <h3>Can I customize the recommendations?</h3>
          <p>
            {
              "Absolutely! After signing up, you'll have access to your personal profile where you can set preferences, interests, and budget ranges. This will help us fine-tune the recommendations to better suit your needs and lifestyle."
            }
          </p>
        </div>
      </div>
    </ThemeProvider>
  );
};
