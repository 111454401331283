// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.signup-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 600px;
  height: 100vh;
  margin: 0px 15px;
}

.signup-form {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.signup-form .field {
  margin-bottom: .5rem;
}

.extra-info-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 600px;
  align-items: center;
  margin: 0px 15px;
}

body {
  margin: 0;
  height: 100%;
  width: 100vw;
  background-color: #f1e5e7;
}

body {
  font-family: "DM Serif Display", serif;
  font-weight: 100;
  font-style: normal;
  color: #9f3d53;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Prata", serif;
  font-weight: 400;
  font-style: normal;
  color: #9f3d53;
}

h1 {
  font-size: 3.75rem;
  margin: auto;
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,YAAY;EACZ,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,sCAAsC;EACtC,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;;;;;;EAME,2BAA2B;EAC3B,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":["#main-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n}\n\n.signup-container {\n  display: flex;\n  flex-direction: column;\n  margin: auto;\n  max-width: 600px;\n  height: 100vh;\n  margin: 0px 15px;\n}\n\n.signup-form {\n  display: flex;\n  flex-direction: column;\n  margin: auto;\n}\n\n.signup-form .field {\n  margin-bottom: .5rem;\n}\n\n.extra-info-container {\n  display: flex;\n  flex-direction: column;\n  margin: auto;\n  max-width: 600px;\n  align-items: center;\n  margin: 0px 15px;\n}\n\nbody {\n  margin: 0;\n  height: 100%;\n  width: 100vw;\n  background-color: #f1e5e7;\n}\n\nbody {\n  font-family: \"DM Serif Display\", serif;\n  font-weight: 100;\n  font-style: normal;\n  color: #9f3d53;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  font-family: \"Prata\", serif;\n  font-weight: 400;\n  font-style: normal;\n  color: #9f3d53;\n}\n\nh1 {\n  font-size: 3.75rem;\n  margin: auto;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
